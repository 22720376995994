import CloseIconSVG from "./icons/CloseIconSVG";
import ExclamationMarkIconSVG from "./icons/ExclamationMarkIconSVG";

const RestoreModalComponent = ({
    hadleModalClose,
    hadleRestoreClick,
    handleCancelRestoreClick,
}) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="w-[416px] h-[328px] bg-white rounded-lg border border-gray-200 flex-col justify-center items-center gap-3 inline-flex">
                <div className="self-stretch px-6 pt-6 pb-3 justify-start items-center inline-flex">
                    <div className="grow shrink basis-0 h-[27px] justify-start items-center flex">
                        <div className="text-[#111928] text-lg font-semibold leading-[27px]">
                            Восстановить данные?
                        </div>
                    </div>
                    <div onClick={hadleModalClose}>
                        <CloseIconSVG />
                    </div>
                </div>
                <ExclamationMarkIconSVG />
                <div className="self-stretch h-[84px] px-6 py-3 flex-col justify-center items-center gap-6 flex">
                    <div className="self-stretch justify-start items-start inline-flex">
                        <div className="grow shrink basis-0 text-gray-500 text-sm font-normal leading-tight">
                            Обнаружена несохраненная версия опроса. Вы хотите
                            восстановить её или продолжить без восстановления?
                        </div>
                    </div>
                </div>
                <div className="self-stretch p-6 justify-start items-center gap-3 inline-flex">
                    <div
                        role="button"
                        onClick={hadleRestoreClick}
                        className="px-5 py-2.5 bg-[#bf1b2c] rounded-lg justify-center items-center gap-2 flex"
                    >
                        <div className="text-white text-sm font-medium leading-tight">
                            Восстановить
                        </div>
                    </div>
                    <div
                        role="button"
                        onClick={handleCancelRestoreClick}
                        className="px-5 py-2.5 rounded-lg border border-[#1f2a37] justify-center items-center gap-2 flex"
                    >
                        <div className="text-[#1f2a37] text-sm font-medium leading-tight">
                            Без восстановления
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RestoreModalComponent;
