const ShuffleIconSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.6153 11.0788C14.5813 10.9975 14.5326 10.9235 14.4713 10.8622L12.4713 8.86217C12.2106 8.6015 11.7893 8.6015 11.5286 8.86217C11.2679 9.12283 11.2679 9.54417 11.5286 9.80483L12.3906 10.6668H10.3473L5.87925 4.28483C5.75459 4.10617 5.55059 4.00017 5.33325 4.00017H1.99992C1.63192 4.00017 1.33325 4.29883 1.33325 4.66683C1.33325 5.03483 1.63192 5.3335 1.99992 5.3335H4.98592L9.45325 11.7155C9.57859 11.8942 9.78259 12.0002 9.99992 12.0002H12.3906L11.5286 12.8622C11.2679 13.1228 11.2679 13.5442 11.5286 13.8048C11.6586 13.9348 11.8293 14.0002 11.9999 14.0002C12.1706 14.0002 12.3413 13.9348 12.4713 13.8048L14.4713 11.8048C14.5326 11.7435 14.5813 11.6695 14.6153 11.5882C14.6826 11.4255 14.6826 11.2415 14.6153 11.0788Z"
                fill="#6B7280"
            />
            <path
                d="M4.98592 10.6668H1.99992C1.63125 10.6668 1.33325 10.9655 1.33325 11.3335C1.33325 11.7015 1.63125 12.0002 1.99992 12.0002H5.33325C5.55059 12.0002 5.75459 11.8942 5.87925 11.7155L7.05592 10.0342L6.24192 8.8715L4.98592 10.6668Z"
                fill="#6B7280"
            />
            <path
                d="M10.3473 5.3335H12.6666L11.4666 6.9335C11.2453 7.22817 11.3053 7.64617 11.5999 7.86683C11.7199 7.95683 11.8606 8.00017 11.9993 8.00017C12.2019 8.00017 12.4019 7.90817 12.5333 7.7335L14.5333 5.06683C14.5513 5.04283 14.5579 5.01483 14.5719 4.98883C14.5906 4.95617 14.6086 4.92483 14.6219 4.88883C14.6399 4.83817 14.6473 4.7875 14.6533 4.73483C14.6553 4.71217 14.6666 4.6915 14.6666 4.66683C14.6666 4.65883 14.6626 4.65283 14.6619 4.6455C14.6599 4.58283 14.6446 4.52283 14.6253 4.46283C14.6186 4.44283 14.6179 4.42017 14.6099 4.40083C14.5793 4.33017 14.5353 4.26483 14.4793 4.20683C14.4759 4.2035 14.4746 4.19883 14.4713 4.19483L12.4719 2.1955C12.2113 1.93483 11.7899 1.93483 11.5293 2.1955C11.2686 2.45617 11.2686 2.8775 11.5293 3.13817L12.3906 4.00017H9.99992C9.78259 4.00017 9.57859 4.10617 9.45392 4.28483L8.27725 5.96617L9.09125 7.12883L10.3473 5.3335Z"
                fill="#6B7280"
            />
        </svg>
    );
};

export default ShuffleIconSVG;
