const FileImageIconSVG = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="file-image">
                <g id="Vector">
                    <path
                        d="M15.055 1.66602H9.16666V5.83268C9.16666 6.75185 8.41916 7.49935 7.49999 7.49935H3.33333V16.666C3.33333 17.5852 4.05583 18.3327 4.94499 18.3327H15.0558C15.9442 18.3327 16.6667 17.5852 16.6667 16.666V3.33268C16.6667 2.41352 15.9442 1.66602 15.055 1.66602ZM12.0833 6.66602C12.7733 6.66602 13.3333 7.22602 13.3333 7.91602C13.3333 8.60602 12.7733 9.16602 12.0833 9.16602C11.3933 9.16602 10.8333 8.60602 10.8333 7.91602C10.8333 7.22602 11.3933 6.66602 12.0833 6.66602ZM13.9342 15.4285C13.7833 15.6793 13.5125 15.8327 13.2192 15.8327H6.55249C6.26666 15.8327 6.00083 15.686 5.8475 15.4444C5.69416 15.2027 5.67666 14.8993 5.79999 14.641L7.99749 10.0318C8.14 9.73352 8.42499 9.54768 8.77833 9.55768C9.10916 9.56935 9.40249 9.77518 9.52499 10.0835L10.51 12.5677L10.9608 11.7368C11.1075 11.4677 11.3492 11.2993 11.6967 11.3018C12.0033 11.3027 12.2842 11.4727 12.4292 11.7435L13.9558 14.6085C14.0925 14.866 14.0842 15.1768 13.9342 15.4285Z"
                        fill="#1F2A37"
                    />
                    <path
                        d="M7.49999 5.83268V1.77435C7.09833 1.88935 6.72583 2.09435 6.42249 2.39852L4.06583 4.75518C3.7625 5.05852 3.55749 5.43102 3.44166 5.83268H7.49999Z"
                        fill="#1F2A37"
                    />
                </g>
            </g>
        </svg>
    );
};

export default FileImageIconSVG;
