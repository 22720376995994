import "./bootstrap";
import React, { useEffect, useState } from "react";
import Alpine from "alpinejs";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import customSurvey from "./customSurvey.json";
import "./App.css";
import axios from "axios";
import { createRoot } from "react-dom/client";
import NewSurveyComponent from "./components/NewSurveyComponent";
import { getCorrectTypes } from "./components/functions";
import PenIconSVG from "./components/icons/PenIconSVG";
import CatAnimationLottie from "./components/assets/lottie-cat.json";
import CloseIconSVG from "./components/icons/CloseIconSVG";
import Lottie from "lottie-react";
import MinusIconSVG from "./components/icons/MinusIconSVG";
import MinusOutlineIconSVG from "./components/icons/MinusOutlineIconSVG";
import ChevronLeftIconSVG from "./components/icons/ChevronLeftIconSVG";

function App({ surveyJson }) {
    console.log(surveyJson);
    const updatedSurveyJson = JSON.parse(JSON.stringify(surveyJson));
    const [mode, setMode] = useState("answer");
    const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isClosed, setIsClosed] = useState(false);

    useEffect(() => {
        if (surveyJson) {
            const user_answers = surveyJson?.user_answers;
            if (user_answers && user_answers.length) {
                setMode("view");
            }
            if (surveyJson?.status === "completed") {
                setIsClosed(true);
            }
            if (surveyJson?.status === "paused") {
                setIsPaused(true);
            }
        }
    }, [surveyJson]);

    updatedSurveyJson.showTitle = false;
    updatedSurveyJson.completeText = "Отправить";
    updatedSurveyJson.completedHtml =
        "<h3>Спасибо за участие в опросе!</h3><p>Ваши ответы были успешно отправлены.</p>";
    if (!updatedSurveyJson.elements && updatedSurveyJson.questions) {
        updatedSurveyJson.elements = updatedSurveyJson.questions;
    }
    const user_answers = surveyJson?.user_answers;

    function isStringifiedArray(str) {
        return (
            typeof str === "string" &&
            str.trim().startsWith("[") &&
            str.trim().endsWith("]")
        );
    }

    updatedSurveyJson.elements?.forEach((element) => {
        // console.log("element", element);
        const type = getCorrectTypes(element.type);

        element.type = type;
        element.choices =
            typeof element.choices === "string"
                ? JSON.parse(element.choices)
                : element.choices;
        if (element.rateValues) {
            element.rateValues =
                typeof element.rateValues === "string"
                    ? JSON.parse(element.rateValues)
                    : element.rateValues;
        }
        if (element.type === "rating") {
            delete element.rateCount;
            delete element.rateMax;
            delete element.rateMin;
        }
        if (user_answers && user_answers.length) {
            element.readOnly = true;
            const answer = user_answers.find(
                (answer) => answer.question_id * 1 === element.id * 1
            );
            // console.log("answer", answer);
            if (answer) {
                const answer_value = isStringifiedArray(answer.answer)
                    ? JSON.parse(answer.answer)
                    : answer.answer;

                if (answer_value) {
                    if (
                        element.type === "file" &&
                        answer_value.includes("http")
                    ) {
                        element.type = "imagepicker";
                        element.choices = [
                            {
                                value: answer.file_name,
                                imageLink: getDefaultAnswer(
                                    element,
                                    answer_value
                                ),
                            },
                        ];
                    } else {
                        element.defaultValue = getDefaultAnswer(
                            element,
                            answer_value
                        );
                    }
                }
            }

            updatedSurveyJson.mode = "display";
        }
    });

    function getDefaultAnswer({ type }, value) {
        console.log("type", type);
        console.log("value", value);

        if (type === "boolean") {
            return value === "true" || value === true ? "true" : "false";
        } else {
            return value;
        }
    }

    const survey = new Model(updatedSurveyJson);
    survey.applyTheme(customSurvey);
    survey.onServerValidateQuestions.add(validateAnswers);

    function validateAnswers(_, { data, errors, complete }) {
        if (mode === "view") {
            // complete();
            return;
        }
        const userAnswers = [];

        Object.keys(data).forEach((questionName) => {
            const question = surveyJson.questions.find(
                (q) => q.name === questionName
            );

            if (question) {
                userAnswers.push({
                    type: question.type,
                    questionId: question.id,
                    answer:
                        question.type === "file"
                            ? data[questionName] // Если это файл, просто используем как есть
                            : typeof data[questionName] === "string"
                            ? data[questionName]
                            : JSON.stringify(data[questionName]),

                    SurveyId: surveyJson.survey_id,
                });
            }
        });

        // Отправляем ответы на сервер

        axios
            .post("/save-answers", {
                elements: userAnswers,
            })
            .then((res) => {
                console.log(res);

                complete();
                setIsCompleteModalOpen(true);
            })
            .catch((err) => {
                console.log(err);
                alert(
                    "Ошибка при сохранении данных на сервере, обновитесь и попробуйте снова"
                );
            });
    }

    return (
        <>
            {updatedSurveyJson.cover ? (
                <div className="self-stretch flex-grow-0 flex-shrink-0 w-[948px] h-[300px] relative overflow-hidden rounded-2xl bg-[#ebf5ff]">
                    <img
                        src={updatedSurveyJson.cover}
                        className="absolute left-[-1px] top-[-170px] object-cover"
                    />
                </div>
            ) : (
                ""
            )}
            <p className="flex-grow-0 flex-shrink-0 w-[948px] text-3xl font-medium text-center text-[#1f2a37] p-8">
                <span className="flex-grow-0 flex-shrink-0 w-[948px] text-3xl font-medium text-center text-[#1f2a37]">
                    {updatedSurveyJson.title}
                </span>
                <br />
                {/* <span className="flex-grow-0 flex-shrink-0 w-[948] text-3xl font-medium text-center text-[#1f2a37]">
                    Отдел персонала
                </span> */}
            </p>
            <div
                className="flex flex-grow-0 flex-shrink-0 w-[948px] p-1 rounded-2xl bg-white"
                style={{
                    boxShadow:
                        "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                }}
            >
                <Survey model={survey} />
            </div>
            {updatedSurveyJson?.is_admin === 1 && (
                <div
                    onClick={() =>
                        (window.location.href = `/surveys/admin/show/${updatedSurveyJson?.survey_id}`)
                    }
                    role="button"
                    className="fixed bottom-8 right-4 w-14 h-14 p-3.5 bg-[#aa1d2b] rounded-lg border-2 border-[#bf1b2c]/10 justify-center items-center inline-flex"
                >
                    <PenIconSVG />
                </div>
            )}
            {isCompleteModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="w-[416px] h-[377px] bg-white rounded-lg border border-gray-200 flex-col justify-start items-start inline-flex">
                        <div className="self-stretch p-6 justify-start items-center inline-flex">
                            <div className="grow shrink basis-0 h-[27px] justify-start items-center flex">
                                <div className="text-[#111928] text-lg font-semibold leading-[27px]">
                                    Спасибо! Вы справились :)
                                </div>
                            </div>
                            <div
                                role="button"
                                onClick={() => {
                                    setIsCompleteModalOpen(false);
                                    location.reload();
                                }}
                            >
                                <CloseIconSVG />
                            </div>
                        </div>
                        <div className="self-stretch h-[212px] p-6 pt-0 flex-col justify-center items-center gap-1 flex">
                            <div className="self-stretch justify-start items-start inline-flex">
                                <div className="grow pt-6 shrink basis-0 text-gray-500 text-sm font-normal leading-tight">
                                    Ваш ответ был записан и передан HR-отделу
                                </div>
                            </div>
                            <div className="">
                                <Lottie
                                    animationData={CatAnimationLottie}
                                    loop={true}
                                    style={{ width: 200, height: 200 }}
                                />
                            </div>
                        </div>
                        <div
                            role="button"
                            onClick={() => setIsCompleteModalOpen(false)}
                            className="self-stretch p-6 justify-start items-center gap-3 inline-flex"
                        >
                            <div
                                role="button"
                                onClick={() => {
                                    setIsCompleteModalOpen(false);
                                    window.location.href = `/surveys/${updatedSurveyJson.next_survey_id}`;
                                }}
                                className="px-5 py-2.5 bg-[#bf1b2c] rounded-lg justify-center items-center gap-2 flex"
                            >
                                <div className="text-white text-sm font-medium leading-tight">
                                    Пройти следующий опрос
                                </div>
                            </div>
                            <div
                                role="button"
                                onClick={() => {
                                    setIsCompleteModalOpen(false);
                                    location.reload();
                                }}
                                className="px-5 py-2.5 rounded-lg border border-[#1f2a37] justify-center items-center gap-2 flex"
                            >
                                <div className="text-[#1f2a37] text-sm font-medium leading-tight">
                                    Закрыть
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(isClosed || isPaused || updatedSurveyJson.status === "draft") && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="w-[469px] h-32 p-4 bg-[#fde8e8] rounded-lg justify-start items-center gap-3 inline-flex">
                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-3 inline-flex">
                            <div className="self-stretch h-[50px] flex-col justify-start items-start gap-1.5 flex">
                                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                                    <MinusOutlineIconSVG />
                                    <div className="grow shrink basis-0 text-[#c81e1e] text-base font-semibold leading-normal">
                                        {isClosed &&
                                            "Опрос больше не действителен."}
                                        {isPaused && "Опрос временно на паузе."}
                                        {updatedSurveyJson.status === "draft" &&
                                            "Опрос еще не опубликован."}
                                    </div>
                                    <div className="w-5 h-5 relative" />
                                </div>
                                <div className="w-[864px]"></div>
                            </div>
                            <div
                                onClick={() => (window.location.href = "/")}
                                role="button"
                                className="px-3 py-2 bg-[#c81e1e] rounded-lg justify-center items-center gap-2 inline-flex"
                            >
                                <ChevronLeftIconSVG />
                                <div className="text-white text-xs font-medium leading-[18px]">
                                    На главную
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default App;

const rootContainer =
    document.getElementById("root") || document.getElementById("survey-data");

if (rootContainer) {
    let surveyJson;
    if (rootContainer.id === "survey-data") {
        surveyJson = JSON.parse(rootContainer.dataset.survey);
    }
    createRoot(rootContainer).render(
        <React.StrictMode>
            <App surveyJson={surveyJson} />
        </React.StrictMode>
    );
}

const newSurveyRoot = document.getElementById("newSurveyRoot");
if (newSurveyRoot) {
    const surveyData = newSurveyRoot.dataset.survey
        ? JSON.parse(newSurveyRoot.dataset.survey)
        : {};
    const isAdmin =
        newSurveyRoot.dataset.user === "true" || surveyData?.is_admin;
    const editMode = newSurveyRoot.dataset.editMode === "true";
    createRoot(newSurveyRoot).render(
        <React.StrictMode>
            <NewSurveyComponent
                isAdmin={isAdmin}
                surveyData={surveyData}
                editMode={editMode}
            />
        </React.StrictMode>
    );
}

window.Alpine = Alpine;

Alpine.start();
