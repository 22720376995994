import React, { useState } from "react";
import SettingsModalComponent from "./SettingsModalComponent";
import Datepicker from "react-tailwindcss-datepicker";
import { formatDateToDateTime } from "./functions";
import { useSurveyStore } from "./stores/useSurveyStore";

const SettingsComponent = ({ departments, users }) => {
    const { settings, setSettings } = useSurveyStore();
    console.log("settings", settings);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [dataValue, setDataValue] = useState({
        startDate: settings.regularity.surveyAvailableFrom,
        endDate: settings.regularity.surveyAvailableTo,
    });

    const handleDataPickerChange = (newValue) => {
        setDataValue(newValue);
        if (newValue) {
            setSettings({
                ...settings,
                regularity: {
                    ...settings.regularity,
                    surveyAvailableFrom: newValue.startDate,
                    surveyAvailableTo: newValue.endDate,
                },
                survey_start: formatDateToDateTime(newValue.startDate),
                survey_end: formatDateToDateTime(newValue.endDate, true),
            });
        }
    };

    // Функция для проверки, является ли строка датой
    function isValidDate(dateString) {
        const date = new Date(dateString);
        return date instanceof Date && !isNaN(date);
    }

    // Функция для проверки, больше ли дата текущей
    function isFutureDate(dateString) {
        const date = new Date(dateString);
        const today = new Date();

        // Сравниваем только даты без учета времени
        return (
            isValidDate(dateString) &&
            date.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)
        );
    }

    // Использование в вашем компоненте
    const placeholderText = isFutureDate(settings.regularity.makeSurveyRegular)
        ? settings.regularity.makeSurveyRegular
        : "с... по...";

    const updateSetting = (category, key, value) => {
        console.log(category, key, value);

        if (key) {
            setSettings({
                ...settings,
                [category]: {
                    ...settings[category],
                    [key]: value,
                },
            });
        } else {
            setSettings({
                ...settings,
                [category]: value,
            });
        }
    };

    const toggleMakeSurveyRegular = (editMode) => {
        console.log("editMode", editMode);

        if (!settings.regularity.makeSurveyRegular || editMode) {
            setModalContent("regularity");
            setIsModalOpen(true);
        } else {
            updateSetting("regularity", "makeSurveyRegular", false);
            // updateSetting("is_regular", "", false);
            // updateSetting("is_regular_from", "", false);
        }
    };

    const handleNotificationsFrequencyChange = (e) => {
        const { value } = e.target;
        console.log(value);
        setModalContent("notifications");
        setIsModalOpen(true);

        // updateSetting("regularity", "surveyNotificationsFrequency", value);
    };

    const handlePrivacyClick = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const toggleStatus = (status) => {
        updateSetting("status", "", status);
    };

    return (
        <div className="flex flex-col justify-start items-start w-[503px] gap-8">
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-5">
                <p className="self-stretch flex-grow-0 flex-shrink-0 w-[503px] text-xl font-medium text-left text-[#1f2a37]">
                    Приватность
                </p>
                <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-3">
                    <div
                        className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-6 p-4 rounded-lg bg-white"
                        style={{
                            boxShadow:
                                "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div
                            className="flex justify-between w-full items-center flex-grow-0 flex-shrink-0 relative gap-12"
                            role="button"
                            onClick={() => handlePrivacyClick("surveyAccess")}
                        >
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Кто может проходить опрос
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#e02424]">
                                {settings.privacy.surveyAccess === "all" &&
                                    "Все"}
                                {settings.privacy.surveyAccess ===
                                    "departments" && "Отделы"}
                                {settings.privacy.surveyAccess === "users" &&
                                    "Пользователи"}
                                {settings.privacy.surveyAccess === "exclude" &&
                                    "Все кроме"}
                            </p>
                        </div>
                    </div>
                    <div
                        className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-6 p-4 rounded-lg bg-white"
                        style={{
                            boxShadow:
                                "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div
                            className="flex justify-between w-full items-center flex-grow-0 flex-shrink-0 relative gap-12"
                            role="button"
                            onClick={() => handlePrivacyClick("viewOwnResults")}
                        >
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Кто может просматривать свой результат
                            </p>

                            <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#e02424]">
                                {settings.privacy.viewOwnResults === "all" &&
                                    "Все"}
                                {settings.privacy.viewOwnResults ===
                                    "rukovoditel" && "Руководители"}
                                {settings.privacy.viewOwnResults === "admin" &&
                                    "Админы"}
                                {settings.privacy.viewOwnResults ===
                                    "exclude" && "Все кроме"}
                            </p>
                        </div>
                    </div>
                    <div
                        className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-6 p-4 rounded-lg bg-white"
                        style={{
                            boxShadow:
                                "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div
                            className="flex justify-between w-full items-center flex-grow-0 flex-shrink-0 relative gap-12"
                            role="button"
                            onClick={() =>
                                handlePrivacyClick("viewOthersResults")
                            }
                        >
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Кто может просматривать чужие результаты
                            </p>

                            <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#e02424]">
                                {settings.privacy.viewOthersResults === "all" &&
                                    "Все"}
                                {settings.privacy.viewOthersResults ===
                                    "rukovoditel" && "Руководители"}
                                {settings.privacy.viewOthersResults ===
                                    "admin" && "Админы"}
                                {settings.privacy.viewOthersResults ===
                                    "exclude" && "Все кроме"}
                            </p>
                        </div>
                    </div>
                    <div
                        className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-6 p-4 rounded-lg bg-white"
                        style={{
                            boxShadow:
                                "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div
                            className="flex justify-between w-full items-center flex-grow-0 flex-shrink-0 relative gap-12"
                            role="button"
                            onClick={() =>
                                toggleStatus(
                                    settings.status === "paused"
                                        ? "active"
                                        : "paused"
                                )
                            }
                        >
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Поставить опрос на паузу
                            </p>
                            <div className="flex-grow-0 flex-shrink-0 w-10 h-5 relative">
                                <div
                                    className={`w-10 h-5 absolute left-[-1px] top-[-1px] rounded-[40px] ${
                                        settings.status === "paused"
                                            ? "bg-[#e02424]"
                                            : "bg-gray-200"
                                    }`}
                                ></div>
                                <div
                                    className={`w-4 h-4 absolute ${
                                        settings.status === "paused"
                                            ? "left-5"
                                            : "left-px"
                                    } top-[0.5px] rounded-[40px] bg-white`}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-6 p-4 rounded-lg bg-white"
                        style={{
                            boxShadow:
                                "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div
                            className="flex justify-between w-full items-center flex-grow-0 flex-shrink-0 relative gap-12"
                            role="button"
                            onClick={() =>
                                toggleStatus(
                                    settings.status === "completed"
                                        ? "active"
                                        : "completed"
                                )
                            }
                        >
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Завершить опрос
                            </p>
                            <div className="flex-grow-0 flex-shrink-0 w-10 h-5 relative">
                                <div
                                    className={`w-10 h-5 absolute left-[-1px] top-[-1px] rounded-[40px] ${
                                        settings.status === "completed"
                                            ? "bg-[#e02424]"
                                            : "bg-gray-200"
                                    }`}
                                ></div>
                                <div
                                    className={`w-4 h-4 absolute ${
                                        settings.status === "completed"
                                            ? "left-5"
                                            : "left-px"
                                    } top-[0.5px] rounded-[40px] bg-white`}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-between w-full items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-5">
                <p className="self-stretch flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#1f2a37]">
                    Регулярность
                </p>
                <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-3">
                    <div
                        className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-6 p-4 rounded-lg bg-white"
                        style={{
                            boxShadow:
                                "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-6">
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Опрос можно пройти
                            </p>
                            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-2">
                                <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 px-2 py-1 rounded-lg bg-gray-50 border border-gray-300">
                                    {/* <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2">
                                        <CalendarIconSVG />
                                        <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-gray-500">
                                            {
                                                settings.regularity
                                                    .surveyAvailableFrom
                                            }
                                        </p>
                                    </div> */}
                                    <Datepicker
                                        primaryColor={"red"}
                                        placeholder={placeholderText}
                                        containerClassName="border-0 flex flex-row-reverse items-center justify-center"
                                        // asSingle={true}
                                        // useRange={false}
                                        popoverDirection="up"
                                        value={dataValue}
                                        inputClassName="bg-gray-50 flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#1f2a37] border-0 focus:border-0 focus:ring-0 focus:outline-none"
                                        toggleClassName="p-0"
                                        onChange={(newValue) =>
                                            handleDataPickerChange(newValue)
                                        }
                                        // displayFormat="YYYY-MM-DD HH:MM:SS"
                                    />
                                </div>
                                {/* <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 px-4 py-3 rounded-lg bg-gray-50 border border-gray-300">
                                    <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2">
                                        <CalendarIconSVG />
                                        <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-gray-500">
                                            {
                                                settings.regularity
                                                    .surveyAvailableTo
                                            }
                                        </p>
                                    </div>
                                    <Datepicker
                                        primaryColor={"red"}
                                        placeholder={placeholderTextEnd}
                                        containerClassName="border-0 flex flex-row-reverse items-center justify-center"
                                        asSingle={true}
                                        useRange={false}
                                        popoverDirection="up"
                                        value={dataValue}
                                        inputClassName="bg-gray-50 flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#1f2a37] border-0 focus:border-0 focus:ring-0 focus:outline-none"
                                        toggleClassName="p-0"
                                        onChange={(newValue) =>
                                            handleDataPickerChange(newValue, "end")
                                        }
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-6 p-4 rounded-lg bg-white"
                        style={{
                            boxShadow:
                                "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div
                            className="flex justify-between w-full items-center flex-grow-0 flex-shrink-0 relative gap-12"
                            role="button"
                            onClick={() => toggleMakeSurveyRegular(true)}
                        >
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Сделать опрос регулярным
                            </p>
                            <div
                                className="flex-grow-0 flex-shrink-0 w-10 h-5 relative"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleMakeSurveyRegular(false);
                                }}
                            >
                                <div
                                    className={`w-10 h-5 absolute left-[-1px] top-[-1px] rounded-[40px] ${
                                        settings.regularity.makeSurveyRegular
                                            ? "bg-[#e02424]"
                                            : "bg-gray-200"
                                    }`}
                                ></div>
                                <div
                                    className={`w-4 h-4 absolute ${
                                        settings.regularity.makeSurveyRegular
                                            ? "left-5"
                                            : "left-px"
                                    } top-[0.5px] rounded-[40px] bg-white`}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 overflow-hidden gap-6 p-4 rounded-lg bg-white"
                        style={{
                            boxShadow:
                                "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                        }}
                    >
                        <div
                            onClick={handleNotificationsFrequencyChange}
                            role="button"
                            className="flex justify-between w-full items-center flex-grow-0 flex-shrink-0 relative gap-12"
                        >
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Как часто приходят уведомления о опросе
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base font-medium text-left text-[#e02424]">
                                {settings.regularity
                                    .surveyNotificationsFrequency === "1" &&
                                    "1 раз"}
                                {settings.regularity
                                    .surveyNotificationsFrequency === "2" &&
                                    "2 раза"}
                                {settings.regularity
                                    .surveyNotificationsFrequency ===
                                    "everyday" && "Пока не пройд."}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <SettingsModalComponent
                    setIsModalOpen={setIsModalOpen}
                    modalContent={modalContent}
                    settings={settings}
                    setSettings={setSettings}
                    departments={departments}
                    usersFromProps={users}
                />
            )}
        </div>
    );
};

export default SettingsComponent;
