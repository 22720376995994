import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSurveyStore } from "./stores/useSurveyStore";
import RadioInput from "./RadioInput";
import CustomSelect from "./CustomSelect";

const ConditionsModalComponent = ({
    setIsModalOpen,
    conditionsSettingsFromProps,
    handleConditionComplete,
    usersFromProps,
    departments,
}) => {
    // console.log("conditionsSettingsFromProps", conditionsSettingsFromProps);
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: "200px", // Задайте нужную ширину контейнера
        }),
        control: (provided) => ({
            ...provided,
            minHeight: "36px", // Изменение минимальной высоты (если нужно)
        }),
        menu: (provided) => ({
            ...provided,
            width: "200px", // Ширина выпадающего меню
        }),
    };

    const { surveyJson, setSurveyJson } = useSurveyStore();

    const conditionsSettings = surveyJson?.elements.find(
        (el) => el.id === conditionsSettingsFromProps?.id
    );
    // console.log("conditionsSettings", conditionsSettings);
    // console.log("surveyJson in ConditionsModalComponent", surveyJson);

    const questions = surveyJson?.elements || surveyJson?.questions || [];
    const [selectedOptions, setSelectedOptions] = useState(
        parseExpression(conditionsSettings?.visibleIf)?.option || []
    );
    const [selectedQuestions, setSelectedQuestions] = useState(
        parseExpression(conditionsSettings?.visibleIf)?.question || []
    );
    const [selectedConditions, setSelectedConditions] = useState(
        parseExpression(conditionsSettings?.visibleIf)?.condition || []
    );
    const [operators, setOperators] = useState(
        parseExpression(conditionsSettings?.visibleIf)?.operators
            ? [
                  { value: "doesn't matter" },
                  ...parseExpression(
                      conditionsSettings?.visibleIf
                  )?.operators.map((o) => ({ value: o })),
              ]
            : [{ value: "doesn't matter" }]
    );
    // console.log("selectedQuestions", selectedQuestions);
    // console.log("selectedConditions", selectedConditions);
    // console.log("selectedOptions", selectedOptions);
    // console.log("operators", operators);

    const [conditionMode, setConditionMode] = useState(
        conditionsSettings?.visibleIf
    );
    const [canAddCondition, setCanAddCondition] = useState(false);
    const [mode, setMode] = useState("logic");
    const [questionsSettings, setQuestionsSettings] = useState({
        id: conditionsSettings?.id,
        questionAccess: conditionsSettings.questionAccess || "all",
        questionAccessExclude: safeJsonParse(
            conditionsSettings.questionAccessExclude
        ),
        questionAccessdepartments: safeJsonParse(
            conditionsSettings.questionAccessdepartments
        ),
        questionAccessusers: safeJsonParse(
            conditionsSettings.questionAccessusers
        ),
    });
    const [users, setUsers] = useState([]);

    function safeJsonParse(data, defaultValue = []) {
        // console.log("data in safeJsonParse", data);

        if (!data) {
            return defaultValue;
        }
        if (typeof data === "number") {
            return [data];
        }
        if (Array.isArray(data)) {
            return data;
        }
        try {
            return JSON.parse(data) || defaultValue;
        } catch (e) {
            console.error("Invalid JSON:", e);
            return defaultValue;
        }
    }

    useEffect(() => {
        if (usersFromProps.length) {
            const users = usersFromProps.map((user) => ({
                value: {
                    id: user.id,
                    name: user.name,
                    department: user.deportament,
                },
                label: user.name,
            }));
            // console.log("users", users);

            setUsers(users);
        }
    }, [usersFromProps]);

    useEffect(() => {
        setCanAddCondition(false);
        if (
            (selectedQuestions?.length,
            selectedConditions?.length,
            selectedOptions?.length,
            operators?.length)
        ) {
            // console.log("selectedQuestions", selectedQuestions);
            // console.log("selectedConditions", selectedConditions);
            // console.log("selectedOptions", selectedOptions);
            // console.log("operators", operators);
            let canComplete = false;
            selectedQuestions.forEach((question, index) => {
                if (
                    question.value &&
                    selectedConditions[index]?.value &&
                    (selectedOptions[index]?.value ||
                        selectedOptions[index]?.value === false) &&
                    operators[index]?.value
                ) {
                    canComplete = true;
                } else {
                    canComplete = false;
                }
            });
            // console.log("canComplete", canComplete);

            if (canComplete) {
                handleConditionComplete(
                    conditionsSettings,
                    selectedQuestions,
                    selectedConditions,
                    selectedOptions,
                    operators
                );
                setCanAddCondition(true);
            }
        }
    }, [selectedQuestions, selectedConditions, selectedOptions]);

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            setIsModalOpen(false);
        }
    };

    function getFormattedQuestionsForSelect() {
        const newQuestions = questions
            ?.filter((q, index) => q.id !== conditionsSettingsFromProps?.id)
            .map((question) => {
                if (question.rateValues) {
                    console.log("question.rateValues", question.rateValues);

                    if (typeof question.rateValues === "string") {
                        question.rateValues = JSON.parse(question.rateValues);
                    }
                }
                return question;
            })
            .map((question) => ({
                ...question,
                value: question.title,
                label: question.title,
                choices:
                    question.choices?.map((choice) => ({
                        value: choice,
                        label: choice,
                    })) || [],
                rateValues: Array.isArray(question.rateValues)
                    ? question.rateValues.map((choice) => ({
                          value: choice,
                          label: choice,
                      }))
                    : [],
            }));
        return newQuestions;
    }

    function getFormattedOptionsForSelect(question) {
        const obj = {
            choices:
                question.choices?.map(
                    (choice) =>
                        typeof choice === "object" && choice !== null
                            ? {
                                  value:
                                      "value" in choice ? choice.value : choice,
                                  label:
                                      "label" in choice ? choice.label : choice,
                              }
                            : { value: choice, label: choice } // Если choice — строка, используем её как value и label
                ) || [],
            rateValues:
                question.rateValues?.map(
                    (choice) =>
                        typeof choice === "object" && choice !== null
                            ? {
                                  value:
                                      "value" in choice ? choice.value : choice,
                                  label:
                                      "label" in choice ? choice.label : choice,
                              }
                            : { value: choice, label: choice } // Аналогично для rateValues
                ) || [],
        };
        console.log("obj", obj);
        return obj;
    }

    function parseExpression(expression) {
        if (!expression) {
            return null;
        }

        // Регулярное выражение для захвата всех условий {question} <> 'value' и операторов and/or
        const regex = /\{(.+?)\}\s*(<>|=)\s*'(.*?)'\s*(and|or)?/g;

        let match;
        const questions = [];
        const conditions = [];
        const options = [];
        const operators = [];

        // Проходим по всем совпадениям в строке
        while ((match = regex.exec(expression)) !== null) {
            const [_, name, condition, option, operator] = match;

            // Добавляем извлеченные данные в соответствующие массивы
            questions.push({
                ...getQustionByName(name.trim()),
                value: getQustionByName(name.trim())?.title,
                label: getQustionByName(name.trim())?.title,
            });

            conditions.push({
                value: condition.trim(),
                label: condition.trim() === "=" ? "равно" : "не равно",
            });

            options.push({
                value: option.trim(),
                label: option.trim(),
            });

            // Если найден оператор and/or, добавляем его в массив operators
            if (operator) {
                operators.push(operator.trim());
            }
        }

        // Если хотя бы одно условие найдено
        if (questions.length > 0) {
            return {
                question: questions,
                condition: conditions,
                option: options,
                operators: operators, // Массив операторов and/or
            };
        }

        // Если формат не совпадает, возвращаем null
        return null;
    }

    function getQustionByName(name) {
        return questions.find((question) => question.name === name);
    }

    function getActiveClass(always = false) {
        if (conditionMode) {
            return always ? "bg-white" : "bg-[#fdf2f2]";
        } else return !always ? "bg-white" : "bg-[#fdf2f2]";
    }

    function handleConditionModeToggle(mode) {
        setConditionMode(mode);
        if (mode === false) {
            // console.log(conditionsSettings);
            const updatedSurveyJson = JSON.parse(JSON.stringify(surveyJson));
            const questions = updatedSurveyJson.elements || [];
            questions.forEach((q) => {
                if (q.id === conditionsSettingsFromProps.id) {
                    q.visibleIf = "";
                }
            });
            setSurveyJson(updatedSurveyJson);
        }
    }

    function handleConditionChange(func, e, index) {
        func((prevState) => {
            // console.log("prevstate", prevState);
            // console.log("e", e);
            // console.log("index", index);
            if (index === prevState.length) {
                return [...prevState, e];
            }
            return (prevState.length > 0 ? prevState : [{}]).map((item, _i) => {
                if (_i === index) {
                    return e;
                }

                return item;
            });
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        console.log(name, value);
        const updatedSettings = { ...questionsSettings };

        if (name === "questionAccess" || name === "questionAccessExclude") {
            updatedSettings[name] = value;
        }

        setQuestionsSettings(updatedSettings);
    };

    const handleCustomSelectChange = (selectedOption, prop1, prop2) => {
        // console.log("selectedOption:", selectedOption);
        // console.log("prop1:", prop1);
        // console.log("prop2:", prop2);

        // if (!selectedOption.length) return;

        const values = Array.isArray(questionsSettings.questionAccess)
            ? questionsSettings.questionAccess
            : [];

        selectedOption.forEach((option) => values.push(option.value.id));
        // console.log("values", values);
        const getFilteredUsers = (condition) => {
            return users
                .filter((user) => condition(user))
                .map((user) => user.value.id)
                .join(",");
        };

        let whoCanTake;

        if (prop2 === "departments") {
            const filtredDepartments = departments.filter((d) =>
                values.includes(d.value.id)
            );
            whoCanTake = getFilteredUsers((user) =>
                filtredDepartments.some(
                    (d) =>
                        d?.value?.name?.toLowerCase() ===
                        user?.value?.department?.toLowerCase()
                )
            );
        } else if (prop2 === "users") {
            whoCanTake = getFilteredUsers((user) =>
                values.includes(user.value.id)
            );
        } else if (prop2 === "Exclude") {
            whoCanTake = getFilteredUsers(
                (user) => !values.includes(user.value.id)
            );
        }

        setQuestionsSettings({
            ...questionsSettings,
            questionAccess: prop2,
            [prop1]: values,
            ...(whoCanTake && { who_can_take: whoCanTake }),
        });
    };

    useEffect(() => {
        // console.log("questionsSettings", questionsSettings);
        // console.log("surveyJson", surveyJson);
        const updatedSurveyJson = JSON.parse(JSON.stringify(surveyJson));
        const questions = updatedSurveyJson.elements || [];
        const questionIndex = questions.findIndex(
            (q) => q.id === questionsSettings.id
        );
        if (questionIndex !== -1) {
            questions[questionIndex] = {
                ...questions[questionIndex],
                ...questionsSettings,
            };
        }
        updatedSurveyJson.questions = questions;
        // console.log("updatedSurveyJson", updatedSurveyJson);

        setSurveyJson(updatedSurveyJson);
    }, [questionsSettings]);

    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
            onClick={handleOutsideClick}
        >
            <div
                className="flex flex-col justify-start items-start relative overflow-visible gap-6 p-12 rounded-lg bg-white w-1/2 h-fit"
                style={{
                    boxShadow:
                        "0px 1px 3px 0 rgba(0,0,0,0.1), 0px -1px 2px -1px rgba(0,0,0,0.1)",
                }}
            >
                <p className="flex-grow-0 flex-shrink-0 text-xl text-left text-[#1f2a37]">
                    <span className="flex-grow-0 flex-shrink-0 text-xl font-medium text-left text-[#1f2a37]">
                        Вопрос «{conditionsSettings?.title}»
                    </span>
                </p>
                <div className="h-[35px] justify-center items-center inline-flex">
                    <div
                        role="button"
                        onClick={() => setMode("logic")}
                        className={
                            "pe-2 w-[124px] self-stretch pb-4 flex-col justify-center items-center inline-flex border-b-[1px] " +
                            (mode === "logic"
                                ? "border-b-[#bf1b2c]"
                                : "border-b-gray-500")
                        }
                    >
                        <div
                            className={
                                "text-sm font-medium leading-tight " +
                                (mode === "logic"
                                    ? "text-[#bf1b2c]"
                                    : "text-gray-500")
                            }
                        >
                            Логика вопросов
                        </div>
                    </div>
                    <div
                        role="button"
                        onClick={() => setMode("whom")}
                        className={
                            "ps-2 w-[124px] self-stretch pb-4 flex-col justify-center items-center inline-flex border-b-[1px] " +
                            (mode === "whom"
                                ? "border-b-[#bf1b2c]"
                                : "border-b-gray-500")
                        }
                    >
                        <div
                            className={
                                "text-sm font-medium leading-tight " +
                                (mode === "whom"
                                    ? "text-[#bf1b2c]"
                                    : "text-gray-500")
                            }
                        >
                            Кому показывать
                        </div>
                    </div>
                </div>
                {mode === "logic" && (
                    <>
                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                            <p className="flex-grow-0 flex-shrink-0 w-[121px] text-base text-left text-gray-600">
                                будет показан
                            </p>
                            <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 space-x-[-1px] rounded-md bg-white">
                                <div
                                    role="button"
                                    className={
                                        "flex justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden pl-4 pr-[18px] py-2 rounded-tl-md rounded-bl-md border border-gray-200 " +
                                        getActiveClass(true)
                                    }
                                    onClick={() => {
                                        handleConditionModeToggle(false);
                                    }}
                                >
                                    <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#111928]">
                                        Всегда
                                    </p>
                                </div>
                                <div
                                    role="button"
                                    className={
                                        "flex justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden px-[17px] py-2 rounded-tr-md rounded-br-md border border-gray-200 " +
                                        getActiveClass(false)
                                    }
                                    onClick={() => {
                                        handleConditionModeToggle(true);
                                    }}
                                >
                                    <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#111928]">
                                        При условии
                                    </p>
                                </div>
                            </div>
                        </div>
                        {conditionMode && (
                            <>
                                {(selectedQuestions.length > 0
                                    ? selectedQuestions
                                    : [{}]
                                ).map((q, index) => (
                                    <div
                                        key={index}
                                        className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-1"
                                    >
                                        {/* {console.log("question", q)} */}
                                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-2">
                                            {index > 0 && (
                                                <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 space-x-[-1px] rounded-md bg-white">
                                                    <div
                                                        role="button"
                                                        className={
                                                            "flex justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden pl-4 pr-[18px] py-2 rounded-tl-md rounded-bl-md border border-gray-200 " +
                                                            getActiveClass(
                                                                operators[index]
                                                                    ?.value !==
                                                                    "and" //немного криво
                                                            )
                                                        }
                                                        onClick={() =>
                                                            handleConditionChange(
                                                                setOperators,
                                                                {
                                                                    value: "and",
                                                                    label: "и",
                                                                },
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#111928]">
                                                            и
                                                        </p>
                                                    </div>
                                                    <div
                                                        role="button"
                                                        className={
                                                            "flex justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden px-[17px] py-2 rounded-tr-md rounded-br-md border border-gray-200 " +
                                                            getActiveClass(
                                                                operators[index]
                                                                    ?.value !==
                                                                    "or" //немного криво
                                                            )
                                                        }
                                                        onClick={() =>
                                                            handleConditionChange(
                                                                setOperators,
                                                                {
                                                                    value: "or",
                                                                    label: "или",
                                                                },
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#111928]">
                                                            или
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            <Select
                                                options={getFormattedQuestionsForSelect()}
                                                onChange={(e) =>
                                                    handleConditionChange(
                                                        setSelectedQuestions,
                                                        e,
                                                        index
                                                    )
                                                }
                                                value={q}
                                                styles={customStyles}
                                            />
                                            <Select
                                                options={[
                                                    {
                                                        value: "=",
                                                        label: "равно",
                                                    },
                                                    {
                                                        value: "<>",
                                                        label: "не равно",
                                                    },
                                                ]}
                                                onChange={(e) =>
                                                    handleConditionChange(
                                                        setSelectedConditions,
                                                        e,
                                                        index
                                                    )
                                                }
                                                value={
                                                    selectedConditions[index]
                                                }
                                            />
                                            {/* {console.log("choices", q?.choices)} */}
                                            {q?.choices &&
                                                q.type !== "yes-no" &&
                                                q?.choices.length > 0 && (
                                                    <Select
                                                        options={
                                                            Array.isArray(
                                                                getFormattedOptionsForSelect(
                                                                    q
                                                                ).choices
                                                            )
                                                                ? getFormattedOptionsForSelect(
                                                                      q
                                                                  ).choices
                                                                : q.choices
                                                        }
                                                        onChange={(e) =>
                                                            handleConditionChange(
                                                                setSelectedOptions,
                                                                e,
                                                                index
                                                            )
                                                        }
                                                        value={
                                                            parseExpression(
                                                                conditionsSettings?.visibleIf
                                                            )?.option
                                                        }
                                                    />
                                                )}

                                            {q.type === "rating" &&
                                                q?.rateValues &&
                                                q?.rateValues.length > 0 && (
                                                    <>
                                                        <Select
                                                            options={
                                                                Array.isArray(
                                                                    getFormattedOptionsForSelect(
                                                                        q
                                                                    ).rateValues
                                                                )
                                                                    ? getFormattedOptionsForSelect(
                                                                          q
                                                                      )
                                                                          .rateValues
                                                                    : q.rateValues
                                                            }
                                                            onChange={(e) =>
                                                                handleConditionChange(
                                                                    setSelectedOptions,
                                                                    e,
                                                                    index
                                                                )
                                                            }
                                                            value={
                                                                selectedOptions[
                                                                    index
                                                                ]
                                                            }
                                                        />
                                                    </>
                                                )}
                                            {q.type === "yes-no" && (
                                                <>
                                                    {console.log(
                                                        "selectedOptions[index]33432",
                                                        selectedOptions[index]
                                                    )}
                                                    <Select
                                                        options={[
                                                            {
                                                                label: "Да",
                                                                value: true,
                                                            },
                                                            {
                                                                label: "Нет",
                                                                value: false,
                                                            },
                                                        ]}
                                                        onChange={(e) =>
                                                            handleConditionChange(
                                                                setSelectedOptions,
                                                                e,
                                                                index
                                                            )
                                                        }
                                                        value={
                                                            selectedOptions[
                                                                index
                                                            ]
                                                        }
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ))}

                                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2 px-3 py-2 rounded-lg border border-gray-200">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                            d="M8 8H4H8Z"
                                            fill="#1F2A37"
                                        ></path>
                                        <path
                                            d="M8 4V8M8 8V12M8 8H12M8 8H4"
                                            stroke="#111928"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    <p
                                        role="button"
                                        className="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#1f2a37]"
                                        onClick={() =>
                                            setSelectedQuestions(
                                                (prevState) => [
                                                    ...prevState,
                                                    {},
                                                ]
                                            )
                                        }
                                    >
                                        Добавить условие
                                    </p>
                                </div>
                            </>
                        )}
                    </>
                )}
                {mode === "whom" && (
                    <div className=" p-4 flex-col justify-start items-start gap-6 inline-flex">
                        <div className="text-[#1f2a37] text-xl font-medium leading-tight">
                            Кто может отвечать на вопрос
                        </div>
                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-3">
                            <RadioInput
                                name="questionAccess"
                                value="all"
                                checked={
                                    questionsSettings.questionAccess === "all"
                                }
                                onChange={handleChange}
                                label="Все"
                            />
                            <RadioInput
                                name="questionAccess"
                                value="departments"
                                checked={
                                    questionsSettings.questionAccess ===
                                    "departments"
                                }
                                onChange={handleChange}
                                label="Выбрать отделы"
                            />
                            {questionsSettings.questionAccess ===
                                "departments" && (
                                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[350px] gap-2 px-px pb-1 rounded-lg">
                                    {console.log(
                                        questionsSettings.questionAccessdepartments
                                    )}
                                    <CustomSelect
                                        options={departments}
                                        placeholder="Выберите отдел"
                                        onChange={(value) =>
                                            handleCustomSelectChange(
                                                value,
                                                "questionAccessdepartments",
                                                "departments"
                                            )
                                        }
                                        value={
                                            Array.isArray(
                                                questionsSettings.questionAccessdepartments
                                            )
                                                ? questionsSettings.questionAccessdepartments.map(
                                                      (el) =>
                                                          departments.find(
                                                              (department) =>
                                                                  department
                                                                      .value
                                                                      .id === el
                                                          )
                                                  )
                                                : []
                                        }
                                    />
                                </div>
                            )}
                            <RadioInput
                                name="questionAccess"
                                value="users"
                                checked={
                                    questionsSettings.questionAccess === "users"
                                }
                                onChange={handleChange}
                                label="Выбрать людей"
                            />
                            {questionsSettings.questionAccess === "users" && (
                                <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[350px] gap-2 px-px pb-1 rounded-lg">
                                    <CustomSelect
                                        options={users}
                                        placeholder="Выберите людей"
                                        onChange={(value) =>
                                            handleCustomSelectChange(
                                                value,
                                                "questionAccessusers",
                                                "users"
                                            )
                                        }
                                        value={
                                            Array.isArray(
                                                questionsSettings.questionAccessusers
                                            )
                                                ? questionsSettings.questionAccessusers.map(
                                                      (el) =>
                                                          users.find(
                                                              (user) =>
                                                                  user.value
                                                                      .id === el
                                                          )
                                                  )
                                                : []
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#e02424]">
                            Добавить исключения
                        </p>
                        <div className="flex justify-between items-center flex-grow-0 flex-shrink-0 relative gap-2">
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-gray-600">
                                Не может проходить опрос
                            </p>
                            <CustomSelect
                                options={users}
                                placeholder="Выберите людей"
                                onChange={(value) => {
                                    console.log("value", value);

                                    handleCustomSelectChange(
                                        value,
                                        "questionAccessExclude",
                                        "Exclude"
                                    );
                                }}
                                menuPlacement="top"
                                value={
                                    Array.isArray(
                                        questionsSettings.questionAccessExclude
                                    )
                                        ? questionsSettings.questionAccessExclude.map(
                                              (el) =>
                                                  users.find(
                                                      (user) =>
                                                          user.value.id === el
                                                  )
                                          )
                                        : []
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConditionsModalComponent;
