import React, { useRef, useState } from "react";
import DragIconVerticalSVG from "./icons/DragIconVerticalSVG";
import EmptySquareIconSVG from "./icons/EmptySquareIconSVG";
import MinusIconSVG from "./icons/MinusIconSVG";
import PlusIconSVG from "./icons/PlusIconSVG";
import { useDrag, useDrop } from "react-dnd";

const Option = ({
    index,
    option,
    type,
    optionHover,
    setOptionHover = () => {},
    handleOptionChange = () => {},
    addOption = () => {},
    removeOption = () => {},
    moveOption = () => {},
    isLastOption,
    id,
    setIsInputFocusedFromParent,
}) => {
    const ref = useRef(null);
    const [isInputFocused, setIsInputFocused] = useState(false);

    const [, drop] = useDrop({
        accept: "OPTION",
        hover: (draggedItem, monitor) => {
            if (!ref.current || isInputFocused) {
                return;
            }
            const dragIndex = draggedItem.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveOption(dragIndex, hoverIndex);
            draggedItem.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: "OPTION",
        item: { type: "OPTION", index, id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => !isInputFocused, // блокируем drag, если инпут в фокусе
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className="option flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 w-[136px] gap-2"
            onMouseEnter={() => setOptionHover(true)}
            onMouseLeave={() => setOptionHover(false)}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: isDragging ? "grabbing" : "pointer",
            }}
            onClick={() => {
                if (isLastOption) {
                    addOption();
                }
            }}
        >
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1">
                {optionHover ? <DragIconVerticalSVG /> : <EmptySquareIconSVG />}
                {!isLastOption ? (
                    <MinusIconSVG onClick={() => removeOption(index)} />
                ) : (
                    <PlusIconSVG onClick={addOption} />
                )}
                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1">
                    <input
                        type={type === "multiple-choice" ? "checkbox" : "radio"}
                        name={`option-${index}`}
                        className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                    />
                    <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 px-1 py-0.5 rounded">
                        <input
                            type="text"
                            value={option}
                            onChange={(e) =>
                                handleOptionChange(index, e.target.value)
                            }
                            onFocus={() => {
                                setIsInputFocusedFromParent(true);
                                setIsInputFocused(true);
                            }}
                            onBlur={() => {
                                setIsInputFocusedFromParent(false);
                                setIsInputFocused(false);
                            }}
                            placeholder={`Ответ ${index + 1}`}
                            name="option"
                            className="option-text border-0 min-w-[600px]" // ширина опции, была 300px, Ярик
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Option;
