const RadioIconUnfilledSVG = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.75"
                y="0.75"
                width="16.5"
                height="16.5"
                rx="8.25"
                fill="#F9FAFB"
            />
            <rect
                x="0.75"
                y="0.75"
                width="16.5"
                height="16.5"
                rx="8.25"
                stroke="#D1D5DB"
                strokeWidth="0.5"
            />
        </svg>
    );
};

export default RadioIconUnfilledSVG;
