import DraggableItem from "./DraggableItem";
import { useSurveyStore } from "./stores/useSurveyStore";

const DraggableItemsList = () => {
    const { items } = useSurveyStore();
    return (
        <div className="relative">
            <div className="sticky top-0 flex flex-col justify-start items-start w-[244px] gap-1">
                {items?.map((item) => (
                    <DraggableItem key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
};

export default DraggableItemsList;
