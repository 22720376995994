const isRequiredIconSVG = ({ isRequired }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M8.66655 2L8.66642 6.84467L12.8627 4.42265L13.5294 5.57735L9.33375 7.99933L13.5294 10.4227L12.8627 11.5773L8.66642 9.15467L8.66655 14H7.33322L7.33309 9.15467L3.13706 11.5773L2.4704 10.4227L6.66573 8L2.4704 5.57735L3.13706 4.42265L7.33309 6.84467L7.33322 2H8.66655Z"
                fill={isRequired ? "red" : "#6B7280"}
            ></path>
        </svg>
    );
};

export default isRequiredIconSVG;
