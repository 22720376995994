const PenIconSVG = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.7396 21.6654L20.6045 13.7997L22.4643 15.6597L14.5993 23.5244L12.7396 21.6654ZM8.47444 17.4001L16.3395 9.53427L18.2005 11.3955L10.3345 19.2612L8.47444 17.4001ZM4.536 26.5775L6.61306 20.3468L11.6518 25.386L5.42159 27.4633L5.41886 27.4642C5.34839 27.4881 5.27442 27.5 5.20052 27.5C5.01822 27.5 4.83924 27.4279 4.70658 27.2945L4.70499 27.2929C4.51787 27.1065 4.45205 26.8287 4.53588 26.5779L4.536 26.5775ZM18.7446 7.13007L20.1095 5.76506C21.7972 4.07831 24.5457 4.07831 26.2333 5.76506C27.922 7.45288 27.922 10.2027 26.2344 11.8905L24.8695 13.2556L18.7446 7.13007Z"
                fill="white"
                stroke="white"
            />
        </svg>
    );
};

export default PenIconSVG;
