const ChevronLeftIconSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.76858 12.6673C9.53333 12.6673 9.30772 12.569 9.1414 12.3942L5.59298 8.66544C5.42668 8.49062 5.33325 8.25356 5.33325 8.00638C5.33325 7.7592 5.42668 7.52213 5.59298 7.34732L9.1414 3.61857C9.22323 3.52954 9.32112 3.45852 9.42935 3.40967C9.53758 3.36081 9.65398 3.3351 9.77177 3.33402C9.88956 3.33294 10.0064 3.35653 10.1154 3.4034C10.2244 3.45027 10.3235 3.51949 10.4068 3.60702C10.4901 3.69454 10.5559 3.79862 10.6005 3.91318C10.6451 4.02775 10.6676 4.1505 10.6666 4.27427C10.6655 4.39805 10.6411 4.52037 10.5946 4.6341C10.5481 4.74783 10.4805 4.85069 10.3958 4.93668L7.47453 8.00638L10.3958 11.0761C10.5198 11.2064 10.6042 11.3725 10.6385 11.5533C10.6727 11.7341 10.6551 11.9215 10.588 12.0918C10.5209 12.2621 10.4072 12.4077 10.2613 12.5102C10.1155 12.6126 9.94401 12.6673 9.76858 12.6673Z"
                fill="white"
            />
        </svg>
    );
};

export default ChevronLeftIconSVG;
