const CopyIconBlackSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.59998 7.19961C5.59998 6.77526 5.76855 6.3683 6.0686 6.06824C6.36866 5.76818 6.77563 5.59961 7.19998 5.59961H12C12.4243 5.59961 12.8313 5.76818 13.1313 6.06824C13.4314 6.3683 13.6 6.77526 13.6 7.19961V11.9996C13.6 12.424 13.4314 12.8309 13.1313 13.131C12.8313 13.431 12.4243 13.5996 12 13.5996H7.19998C6.77563 13.5996 6.36866 13.431 6.0686 13.131C5.76855 12.8309 5.59998 12.424 5.59998 11.9996V7.19961Z"
                fill="#1F2A37"
            />
            <path
                d="M4.00002 2.40039C3.57568 2.40039 3.16871 2.56896 2.86865 2.86902C2.5686 3.16908 2.40002 3.57604 2.40002 4.00039V8.80039C2.40002 9.22474 2.5686 9.6317 2.86865 9.93176C3.16871 10.2318 3.57568 10.4004 4.00002 10.4004V4.00039H10.4C10.4 3.57604 10.2315 3.16908 9.93139 2.86902C9.63134 2.56896 9.22437 2.40039 8.80002 2.40039H4.00002Z"
                fill="#111928"
            />
        </svg>
    );
};

export default CopyIconBlackSVG;
