const RadioInput = ({ name, value, checked, onChange, label }) => {
    return (
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0">
            <label className="flex items-center gap-2">
                <input
                    type="radio"
                    name={name}
                    value={value}
                    className="form-radio text-[#AA1D2B] h-4 w-4"
                    checked={checked}
                    onChange={onChange}
                />
                <span className="text-base text-left text-[#111928]">
                    {label}
                </span>
            </label>
        </div>
    );
};

export default RadioInput;
