const CloseIconSVG = () => {
    return (
        <svg
            role="button"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.1884 10.0106L15.5992 5.59973C15.6788 5.52286 15.7423 5.43091 15.786 5.32924C15.8297 5.22757 15.8527 5.11822 15.8536 5.00757C15.8546 4.89692 15.8335 4.78718 15.7916 4.68477C15.7497 4.58236 15.6878 4.48931 15.6096 4.41107C15.5313 4.33283 15.4383 4.27095 15.3359 4.22905C15.2335 4.18715 15.1237 4.16606 15.0131 4.16702C14.9024 4.16799 14.7931 4.19097 14.6914 4.23465C14.5897 4.27832 14.4978 4.34181 14.4209 4.4214L10.0101 8.83223L5.59924 4.4214C5.44208 4.2696 5.23157 4.18561 5.01308 4.1875C4.79458 4.1894 4.58557 4.27704 4.43106 4.43155C4.27656 4.58606 4.18891 4.79507 4.18702 5.01356C4.18512 5.23206 4.26911 5.44256 4.42091 5.59973L8.83174 10.0106L4.42091 14.4214C4.34132 14.4983 4.27783 14.5902 4.23416 14.6919C4.19049 14.7936 4.1675 14.9029 4.16654 15.0136C4.16557 15.1242 4.18666 15.2339 4.22856 15.3364C4.27046 15.4388 4.33234 15.5318 4.41058 15.6101C4.48883 15.6883 4.58187 15.7502 4.68428 15.7921C4.7867 15.834 4.89643 15.8551 5.00708 15.8541C5.11773 15.8531 5.22708 15.8302 5.32875 15.7865C5.43042 15.7428 5.52237 15.6793 5.59924 15.5997L10.0101 11.1889L14.4209 15.5997C14.5781 15.7515 14.7886 15.8355 15.0071 15.8336C15.2256 15.8317 15.4346 15.7441 15.5891 15.5896C15.7436 15.4351 15.8312 15.2261 15.8331 15.0076C15.835 14.7891 15.751 14.5786 15.5992 14.4214L11.1884 10.0106Z"
                fill="#9CA3AF"
            />
        </svg>
    );
};

export default CloseIconSVG;
