import React, { useState, useEffect, useRef } from "react";
import { useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SurveyEditor from "./SurveyEditor";
import { useSurveyStore } from "./stores/useSurveyStore";

const DraggableArea = ({ handleConditionsModalOpenClick }) => {
    const { droppedItems: items, setDroppedItems: setItems } = useSurveyStore();
    const { items: allItems } = useSurveyStore();

    const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
    const [highlightedItemId, setHighlightedItemId] = useState(null);
    const newItemRef = useRef(null);

    useEffect(() => {
        setHoveredItemIndex(null);
    }, [items]);

    useEffect(() => {
        if (highlightedItemId && newItemRef.current) {
            // Прокрутить до нового элемента
            newItemRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
            // Удалить подсветку через 2 секунды
            const timeout = setTimeout(() => setHighlightedItemId(null), 2000);
            return () => clearTimeout(timeout);
        }
    }, [highlightedItemId]);

    const [{ isOver }, drop] = useDrop({
        accept: ["ITEM", "EXISTING_ITEM"],
        drop: (item, monitor) => {
            if (monitor.getItemType() === "ITEM") {
                addItemToContainer(item.id);
            } else if (monitor.getItemType() === "EXISTING_ITEM") {
                const { index: dragIndex } = item;
                if (hoveredItemIndex !== null) {
                    moveItem(dragIndex, hoveredItemIndex);
                }
            }
        },
        hover: (item, monitor) => {
            if (monitor.getItemType() === "EXISTING_ITEM") {
                setHoveredItemIndex(item.index);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const addItemToContainer = (id) => {
        const el = {
            type_id: id,
            id: `sk-${Date.now()}`,
        };
        setItems([...items, el]);
        setHighlightedItemId(el.id); // Установить ID нового элемента для подсветки
    };

    const moveItem = (dragIndex, hoverIndex) => {
        if (dragIndex === hoverIndex || hoverIndex === null) return;

        const newItems = [...items];
        const [draggedItem] = newItems.splice(dragIndex, 1);

        newItems.splice(hoverIndex, 0, draggedItem);
        setItems(newItems);
    };

    const getItemById = (id) => {
        return allItems?.find((item) => item.id === id);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div ref={drop} className="min-h-80 pb-48 ">
                {" "}
                {/* убрал pr-96, потому что он шапку отодвигал, Ярик */}
                {!items?.length ? (
                    <div className="flex flex-col justify-center items-center w-[596px] h-[228px] gap-2.5 rounded-lg bg-gray-50 border-2 border-gray-200 border-dashed">
                        <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 gap-4">
                            <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[7px]">
                                <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="flex-grow-0 flex-shrink-0 w-10 h-10 relative"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    {/* SVG content */}
                                </svg>
                                <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-gray-500">
                                    Добавьте сюда вопросы формы
                                </p>
                            </div>
                            {/* <div
                                className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2 px-3 py-2 rounded-lg bg-gray-200"
                                role="button"
                                onClick={() =>
                                    alert("У вас пока нет созданных опросов")
                                }
                            >
                                <p className="flex-grow-0 flex-shrink-0 text-xs font-medium text-left text-[#1f2a37]">
                                    Использовать созданный опрос
                                </p>
                            </div> */}
                        </div>
                    </div>
                ) : (
                    <div className=" flex flex-col gap-6 ">
                        {items?.map((el, index) => {
                            const item = getItemById(el.type_id);
                            return (
                                <div
                                    key={el.id}
                                    ref={
                                        el.id === highlightedItemId
                                            ? newItemRef
                                            : null
                                    } // Реф для нового элемента
                                    className={`${
                                        el.id === highlightedItemId
                                            ? "border-2 border-red-600/50 rounded-2xl"
                                            : ""
                                    } transition-colors duration-500`}
                                >
                                    <SurveyEditor
                                        id={el.id}
                                        type={item?.type}
                                        index={index}
                                        moveItem={moveItem}
                                        tempItem={el}
                                        isRequired={
                                            el.isRequired === "true" ||
                                            el.isRequired === true ||
                                            false
                                        }
                                        handleConditionsModalOpenClick={
                                            handleConditionsModalOpenClick
                                        }
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </DndProvider>
    );
};

export default DraggableArea;
