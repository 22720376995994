import React, { useState, useRef, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import DragIconHorizontalSVG from "./icons/DragIconHorizontalSVG";
import MinusIconSVG from "./icons/MinusIconSVG";
import PlusIconSVG from "./icons/PlusIconSVG";
import BasketIconSVG from "./icons/BasketIconSVG";
import CopyIconSVG from "./icons/CopyIconSVG";
import isRequiredIconSVG from "./icons/RequiredIconSVG";
import EditIconSVG from "./icons/EditIconSVG";
import Option from "./Option";
import ButtonComponent from "./ButtonComponent";
import RatingBlock from "./RatingBlock";
import BoardedSquareIconSVG from "./icons/BoardedSquareIconSVG";
import ShuffleIconSVG from "./icons/ShuffleIconSVG";
import { useSurveyStore } from "./stores/useSurveyStore";

const SurveyEditor = ({
    id,
    type,
    index,
    moveItem,
    handleConditionsModalOpenClick,
    isRequired,
}) => {
    const {
        duplicateQuestion: onDuplicate,
        removeQuestion: onDelete,
        surveyJson,
        setSurveyJson,
    } = useSurveyStore();

    // console.log("surveyJson in SurveyEditor", surveyJson);

    const [optionHover, setOptionHover] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);

    function getChoices() {
        if (type === "rating") {
            return [];
        } else return [""];
    }

    const [item, setItem] = useState(() => {
        const foundElement = surveyJson.elements?.find((el) => el.id === id);

        return foundElement
            ? {
                  id: foundElement.id,
                  name: foundElement.name || foundElement.id || "",
                  type: foundElement.type || type,
                  title: foundElement.title || "",
                  //   isRequired: foundElement.isRequired === "true" || false,
                  isRequired: isRequired,
                  choices: foundElement.choices || getChoices(),
                  rateValues: foundElement.rateValues || [],
                  visibleIf: foundElement.visibleIf || "",
                  who_can_take: foundElement.who_can_take || "",
              }
            : {
                  id: id,
                  name: `${id}` || "",
                  type: type,
                  title: "",
                  isRequired: false,
                  choices: getChoices(),
                  rateValues: [],
                  visibleIf: "",
                  who_can_take: "",
              };
    });
    // console.log("surveyJson in SurveyEditor", surveyJson);

    // console.log("item", item);

    const [ratings, setRatings] = useState([]);
    const [panelType, setPanelType] = useState([]);

    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: "EXISTING_ITEM",
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
        }),
        hover: (item, monitor) => {
            // console.log("item", item);
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: "EXISTING_ITEM",
        item: { id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => !isInputFocused, // блокируем drag, если инпут в фокусе
    });

    drag(drop(ref));

    useEffect(() => {
        // console.log("Starting useEffect, current surveyJson:", surveyJson);
        // console.log("Starting useEffect, current item:", item);

        const updatedSurveyJson = JSON.parse(JSON.stringify(surveyJson));
        // console.log("Cloned updatedSurveyJson:", updatedSurveyJson);

        const questionInSurvey = updatedSurveyJson?.questions?.find(
            (element) => element.id === item.id
        );
        // console.log("Found questionInSurvey (if any):", questionInSurvey);

        if (!questionInSurvey) {
            // console.log("Question not found, adding new item:", item);
            updatedSurveyJson.questions.push(item);
            updatedSurveyJson.elements = updatedSurveyJson.questions;
        } else {
            // console.log("Question found, updating with item:", item);
            Object.assign(questionInSurvey, item);
            // console.log("Updated questionInSurvey:", questionInSurvey);
        }

        updatedSurveyJson.elements = updatedSurveyJson.questions;
        // console.log("Final updatedSurveyJson before set:", updatedSurveyJson);

        setSurveyJson(updatedSurveyJson);
    }, [item]);

    useEffect(() => {
        if (ratings.length || panelType.length) {
            setItem({
                ...item,
                rateValues: [...ratings, ...panelType],
                autoGenerate: panelType.length ? false : true,
            });
        }
    }, [ratings, panelType]);

    const handleQuestionTextChange = (e) => {
        setItem({ ...item, title: e.target.value });
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...item.choices];
        newOptions[index] = value;
        setItem({ ...item, choices: newOptions });
    };

    const addOption = () => {
        setItem({ ...item, choices: [...item.choices, ""] });
    };

    const removeOption = (index) => {
        const newOptions = [...item.choices];
        newOptions.splice(index, 1);
        setItem({ ...item, choices: newOptions });
    };

    const moveOption = (dragIndex, hoverIndex) => {
        const newOptions = [...item.choices];
        const draggedOption = newOptions[dragIndex];
        newOptions.splice(dragIndex, 1);
        newOptions.splice(hoverIndex, 0, draggedOption);
        setItem({ ...item, choices: newOptions });
    };

    const handleisRequiredChange = () => {
        const isRequired = !item.isRequired;
        setItem({ ...item, isRequired });
    };

    const renderOptions = (type) => {
        return (
            item.choices && typeof item.choices === "string"
                ? JSON.parse(item.choices)
                : item.choices
        )?.map((option, index) => (
            <Option
                key={index}
                index={index}
                option={option}
                type={type}
                optionHover={optionHover}
                setOptionHover={setOptionHover}
                handleOptionChange={handleOptionChange}
                addOption={addOption}
                removeOption={removeOption}
                moveOption={moveOption}
                isLastOption={index === item.choices.length - 1}
                id={option.id || Date.now()}
                setIsInputFocusedFromParent={setIsInputFocused}
            />
        ));
    };

    const formatRatingQuestion = (
        value = "Не взаимодействую с данным отделом"
    ) => {
        setPanelType((prevState) => [...prevState, value]);
        // setRatings((prevState) => [...prevState, value]);
    };

    const handlePanelTypeChange = (newValue, prevValue) => {
        setPanelType((prevState) => {
            const prev = prevState.find((v) => v === prevValue);
            if (prev) {
                const newState = prevState.filter((v) => v !== prev);
                return [...newState, newValue];
            } else {
                return [...prevState, newValue];
            }
        });
    };

    useEffect(() => {
        let ratings =
            typeof item.rateValues === "string"
                ? JSON.parse(item.rateValues)
                : item.rateValues;

        if (ratings.length) {
            let numbers;
            if (typeof ratings === "string") {
                numbers = ratings.split(",").map((item) => {
                    const number = item * 1; // Приводим item к числу
                    return number;
                });
            } else {
                numbers = ratings.filter((item) => {
                    const number = item * 1; // Приводим item к числу
                    return !isNaN(number);
                });
            }
            console.log("numbers:", numbers);

            setRatings(numbers);

            const btns = ratings.filter((item) => {
                const number = item * 1; // Приводим item к числу
                return isNaN(number);
            });

            setPanelType(btns);
        } else {
            setRatings([1, 2, 3, 4, 5]);
            setPanelType([]);
        }
    }, []);

    useEffect(() => {
        const foundElement = surveyJson.elements?.find((el) => el.id === id);
        if (
            foundElement &&
            (foundElement.visibleIf !== item.visibleIf ||
                foundElement.who_can_take !== item.who_can_take)
        ) {
            setItem((prevItem) => ({
                ...prevItem,
                visibleIf: foundElement.visibleIf,
                who_can_take: foundElement.who_can_take,
            }));
        }
    }, [surveyJson, id]);

    const renderQuestionType = (type) => {
        // console.log(type);

        switch (type) {
            case "multiple-choice":
                return <div>{renderOptions(type)}</div>;
            case "single-choice":
                return <div>{renderOptions(type)}</div>;

            case "dropdown":
                return (
                    <div>
                        <select
                            className="self-stretch flex-grow-0 flex-shrink-0 w-[600px] h-[47px] relative rounded bg-gray-50 border border-gray-200 mb-2" // было w-[456px], Ярик
                            disabled
                        ></select>
                        {renderOptions()}
                    </div>
                );
            case "short-text":
                return (
                    <input
                        className="self-stretch flex-grow-0 flex-shrink-0 w-[600px] h-[47px] relative rounded bg-gray-50 border border-gray-200 mb-2" // было w-[456px], Ярик
                        type="text"
                        disabled
                    />
                );
            case "long-text":
                return (
                    <textarea
                        className="self-stretch flex-grow-0 flex-shrink-0 w-[700px] relative rounded bg-gray-50 border border-gray-200 mb-2" // было w-[456px], Ярик
                        disabled
                        rows="3"
                    />
                );
            case "yes-no":
                return (
                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2">
                        <label>Да</label>
                        <input
                            type="radio"
                            name={`yes-no-${id}`}
                            value="yes"
                            className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                        />
                        <label>Нет</label>
                        <input
                            type="radio"
                            name={`yes-no-${id}`}
                            value="no"
                            className="flex-grow-0 flex-shrink-0 w-4 h-4 relative"
                        />
                    </div>
                );
            case "rating":
                return (
                    <div className="flex flex-col justify-start items-center gap-2">
                        <div
                            className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-2"
                            data-rating={ratings.length}
                        >
                            <MinusIconSVG
                                onClick={() => setRatings(ratings.slice(0, -1))}
                            />
                            <PlusIconSVG
                                onClick={() =>
                                    setRatings([...ratings, ratings.length + 1])
                                }
                            />
                            {/* <label>{`Rating (1-${ratings.length}):`}</label> */}
                            {ratings.map((rating) => (
                                <label
                                    key={rating}
                                    className="flex-grow-0 flex-shrink-0 w-12 h-12 relative rounded-[100px] bg-white cursor-pointer"
                                    style={{
                                        boxShadow:
                                            "0px 1px 2px 0 rgba(0,0,0,0.15)",
                                    }}
                                    role="button"
                                >
                                    <input
                                        type="radio"
                                        name={`rating-${id}`}
                                        value={rating}
                                        className="absolute w-full h-full opacity-0 cursor-pointer"
                                    />
                                    <span className="w-[9.3px] h-[21px] absolute left-[19.5px] top-[13.23px] text-sm text-center text-[#111928]">
                                        {rating}
                                    </span>
                                </label>
                            ))}
                        </div>
                        {panelType?.length > 0 && (
                            <div>
                                {panelType?.map((item, index) => (
                                    <RatingBlock
                                        key={index}
                                        title={item}
                                        handlePanelTypeChange={
                                            handlePanelTypeChange
                                        }
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                );
            case "file":
                return <input type="file" disabled />;
            default:
                return null;
        }
    };

    return (
        <>
            <div
                ref={ref}
                className="survey-sk flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative px-5 py-3 rounded-2xl bg-white"
                style={{
                    width: "90vw", // Адаптивная ширина — 90% от ширины экрана
                    maxWidth: "800px", // Ограничение на 800px для больших экранов, Ярик
                    boxShadow: "0px 1px 2px 0 rgba(0,0,0,0.08)",
                    opacity: isDragging ? 0.5 : 1,
                }}
                data-handler-id={handlerId}
                id={id ? id : `survey-item-${index}`}
            >
                <DragIconHorizontalSVG />
                <div className="flex flex-col justify-start items-end self-stretch flex-grow-0 flex-shrink-0 gap-3">
                    <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-3">
                        <div
                            className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1"
                            data-sk-question-type={
                                type === "rating" && panelType ? "panel" : type
                            }
                            data-required={item.isRequired}
                        >
                            <p
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                                onClick={() => setIsEditing(true)}
                                className="sk-questionText flex gap-1 items-center flex-grow-0 flex-shrink-0 text-sm font-semibold text-left text-[#111928] break-words whitespace-normal max-w-[700px]" // Добавлено max-w-[600px], break-words и whitespace-normal, Ярик
                            >
                                {!isEditing ? (
                                    item.title ? (
                                        <>
                                            {item.title}
                                            {hovered && <EditIconSVG />}
                                        </>
                                    ) : (
                                        <>
                                            Вопрос {index + 1}
                                            {hovered && <EditIconSVG />}
                                        </>
                                    )
                                ) : (
                                    <textarea
                                        type="text"
                                        value={item.title}
                                        onChange={handleQuestionTextChange}
                                        placeholder="Введите текст вопроса"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                setIsEditing(false);
                                            }
                                        }}
                                        className="border-0 min-w-[700px] break-words whitespace-normal" // было 400, поле вопроса, поменял input на textareaЯрик
                                        autoFocus={true}
                                        onFocus={() => setIsInputFocused(true)}
                                        onBlur={() => setIsInputFocused(false)}
                                    />
                                )}
                            </p>
                        </div>
                        {renderQuestionType(item.type)}
                    </div>
                    <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 gap-3">
                        {type === "rating" && (
                            <div
                                onClick={() =>
                                    formatRatingQuestion(
                                        "Не взаимодействую с этим отделом"
                                    )
                                }
                                role="button"
                                className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-1 px-2 py-1 rounded-lg"
                            >
                                <BoardedSquareIconSVG />
                                <p className="flex-grow-0 flex-shrink-0 text-xs text-left text-gray-600">
                                    Добавить кнопку
                                </p>
                            </div>
                        )}
                        <ButtonComponent
                            onClick={() =>
                                handleConditionsModalOpenClick(item, index)
                            }
                            icon={ShuffleIconSVG}
                            text={"Условие"}
                            textClass="text-gray-600"
                            alwaysActive={!!item.visibleIf || item.who_can_take}
                        />
                        <ButtonComponent
                            onClick={() => onDuplicate(item, index)}
                            icon={CopyIconSVG}
                            text={"Дублировать"}
                            textClass="text-gray-600"
                        />
                        <ButtonComponent
                            onClick={handleisRequiredChange}
                            icon={isRequiredIconSVG}
                            text={"Обязательный"}
                            textClass={
                                item.isRequired
                                    ? "text-red-500"
                                    : "text-gray-600"
                            }
                            isRequired={item.isRequired}
                        />
                        <ButtonComponent
                            onClick={() => onDelete(index)}
                            icon={BasketIconSVG}
                            text={"Удалить"}
                            textClass="text-gray-600"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SurveyEditor;
