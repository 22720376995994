import React, { useState } from "react";
import customSurvey from "../../customSurvey.json";
import { Model } from "survey-core";
import { getCorrectTypes } from "../functions";
import { Survey } from "survey-react-ui";
import { useSurveyStore } from "../stores/useSurveyStore";
import TrashIconSVG from "../icons/TrashIconSVG";
import PrinterIconSVG from "../icons/PrinterIconSVG";
import axios from "axios";
import CloseIconSVG from "../icons/CloseIconSVG";

const UserMode = ({}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { surveyJson, setSurveyJson } = useSurveyStore();

    // console.log("surveyJson", surveyJson);
    const updatedSurveyJson = JSON.parse(JSON.stringify(surveyJson));
    updatedSurveyJson.showTitle = false;

    const [userIndex, setUserIndex] = useState(0);

    const user_answers = updatedSurveyJson?.user_answers;
    // console.log("user_answers", user_answers);

    const users = [];
    user_answers?.forEach((user_answer) => {
        const user = users.find((user) => user.id === user_answer.user_id);
        if (!user) {
            users.push({
                id: user_answer.user_id,
                name: user_answer.user_name,
            });
        }
    });
    users.sort((a, b) => a.id * 1 - b.id * 1);
    // console.log("users", users);

    updatedSurveyJson.elements?.forEach((element) => {
        // console.log("element", element);
        const type = getCorrectTypes(element.type);

        element.type = type;
        if (user_answers && user_answers.length) {
            element.readOnly = true;
            const answer = user_answers
                .filter((a) => a.user_id === users[userIndex]?.id)
                .find((answer) => answer.question_id * 1 === element.id * 1);
            // console.log("answer", answer);
            if (answer) {
                const answer_value = isStringifiedArray(answer.answer)
                    ? JSON.parse(answer.answer)
                    : answer.answer;

                if (answer_value) {
                    if (
                        element.type === "file" &&
                        answer_value.includes("http")
                    ) {
                        element.type = "imagepicker";
                        element.choices = [
                            {
                                value: answer.file_name,
                                imageLink: getDefaultAnswer(
                                    element,
                                    answer_value
                                ),
                            },
                        ];
                    } else {
                        element.defaultValue = getDefaultAnswer(
                            element,
                            answer_value
                        );
                    }
                }
            }

            updatedSurveyJson.mode = "display";
        }
    });

    function getDefaultAnswer({ type }, value) {
        // console.log("type", type);
        // console.log("value", value);

        if (type === "boolean") {
            return value === "true" || value === true ? "true" : "false";
        } else {
            return value;
        }
    }

    function isStringifiedArray(str) {
        return (
            typeof str === "string" &&
            str.trim().startsWith("[") &&
            str.trim().endsWith("]")
        );
    }

    const survey = new Model(updatedSurveyJson);
    survey.css["body"] = "sd-body m-0";

    survey.applyTheme(customSurvey);

    const handleSelectChange = (event) => {
        const selectedIndex = event.target.selectedIndex;
        setUserIndex(selectedIndex);
    };

    const handleDeleteUserAnswers = () => {
        setIsModalOpen(true); // Открываем модальное окно
    };

    const confirmDeleteUserAnswers = async () => {
        try {
            const updatedSurveyJson = JSON.parse(JSON.stringify(surveyJson));
            const user_answers_to_delete =
                updatedSurveyJson?.user_answers.filter(
                    (user_answer) => user_answer.user_id === users[userIndex].id
                );
            console.log("user_answers_to_delete", user_answers_to_delete);

            const res = await axios.post("/admin/delete/all/answers/multiple", {
                answerIds: user_answers_to_delete.map((user_answer) => {
                    return user_answer.answer_id;
                }),
            });
            // console.log("res", res);

            updatedSurveyJson.user_answers =
                updatedSurveyJson.user_answers.filter(
                    (user_answer) =>
                        user_answer?.user_id !== users[userIndex]?.id
                );

            setSurveyJson(updatedSurveyJson);
            setIsModalOpen(false); // Закрываем модальное окно после подтверждения
        } catch (error) {
            console.error("Error deleting user answers:", error);
            setIsModalOpen(false);
            alert("Ошибка при удалении ответов пользователей");
        }
    };

    return (
        <>
            <div className="p-5 bg-white rounded-xl shadow border border-gray-100 flex-col justify-center items-start gap-5 inline-flex">
                <div className="self-stretch flex-col justify-start items-start gap-12 flex">
                    <div className="self-stretch justify-between items-center gap-12 inline-flex">
                        <select
                            className="w-[350px] px-4 py-3.5 bg-gray-50 rounded-lg border border-gray-300 flex-col justify-start items-start inline-flex"
                            value={users[userIndex]?.id}
                            onChange={handleSelectChange}
                        >
                            {users?.map((user) => (
                                <option key={user.id} value={user.id}>
                                    {user.name}
                                </option>
                            ))}
                        </select>

                        <div className="h-8 justify-start items-center gap-3 flex">
                            <div className="grow shrink basis-0 h-8 rounded border border-gray-300 justify-start items-center flex">
                                <div
                                    role="button"
                                    onClick={() =>
                                        setUserIndex((prev) =>
                                            prev > 0 ? prev - 1 : 0
                                        )
                                    }
                                    className={`self-stretch px-3 py-1.5 flex-col justify-start items-center inline-flex 
        ${
            userIndex > 0
                ? "bg-white cursor-pointer"
                : "bg-gray-200 cursor-not-allowed"
        }`}
                                >
                                    <div
                                        className={`text-center text-sm font-medium leading-tight 
        ${userIndex > 0 ? "text-gray-500" : "text-gray-400"}`}
                                    >
                                        Прошлый
                                    </div>
                                </div>

                                <div
                                    role="button"
                                    onClick={() =>
                                        setUserIndex((prev) =>
                                            prev < users.length - 1
                                                ? prev + 1
                                                : users.length - 1
                                        )
                                    }
                                    className={`self-stretch px-3 py-1.5 flex-col justify-center items-center inline-flex 
        ${
            userIndex < users.length - 1
                ? "bg-[#bf1b2c]/5 cursor-pointer"
                : "bg-gray-200 cursor-not-allowed"
        }`}
                                >
                                    <div
                                        className={`text-center text-sm font-medium leading-tight 
        ${userIndex < users.length - 1 ? "text-[#aa1d2b]" : "text-gray-400"}`}
                                    >
                                        Следующий
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <span className="text-[#111928] text-sm font-semibold leading-[21px]">
                                    {userIndex + 1}{" "}
                                </span>
                                <span className="text-gray-700 text-sm font-normal leading-tight">
                                    из{" "}
                                </span>
                                <span className="text-[#111928] text-sm font-semibold leading-[21px]">
                                    {users.length}{" "}
                                </span>
                                <span className="text-gray-700 text-sm font-normal leading-tight">
                                    респондентов
                                </span>
                            </div>
                        </div>
                        <div className="flex gap-3">
                            <div
                                role="button"
                                onClick={() =>
                                    handleDeleteUserAnswers(userIndex)
                                }
                            >
                                <TrashIconSVG />
                            </div>
                            <div role="button" onClick={() => window.print()}>
                                <PrinterIconSVG />
                            </div>
                        </div>
                    </div>
                    <Survey model={survey} />
                </div>
            </div>
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="w-[416px] h-[242px] bg-white rounded-lg border border-gray-200 flex-col justify-start items-start inline-flex">
                        <div
                            onClick={() => setIsModalOpen(false)}
                            className="self-stretch px-4 pt-4 justify-end items-center inline-flex"
                        >
                            <CloseIconSVG />
                        </div>
                        <div className="self-stretch h-[146px] px-6 py-5 flex-col justify-start items-center gap-4 flex">
                            <svg
                                width="42"
                                height="42"
                                viewBox="0 0 42 42"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M21 3.5C17.5388 3.5 14.1554 4.52636 11.2775 6.44928C8.39967 8.37221 6.15665 11.1053 4.83212 14.303C3.50758 17.5007 3.16102 21.0194 3.83627 24.4141C4.51151 27.8087 6.17822 30.9269 8.62564 33.3744C11.0731 35.8218 14.1913 37.4885 17.5859 38.1637C20.9806 38.839 24.4993 38.4924 27.697 37.1679C30.8947 35.8433 33.6278 33.6003 35.5507 30.7225C37.4736 27.8446 38.5 24.4612 38.5 21C38.4949 16.3603 36.6495 11.912 33.3687 8.63126C30.088 5.35048 25.6397 3.5051 21 3.5ZM21 29.75C20.6539 29.75 20.3155 29.6474 20.0278 29.4551C19.74 29.2628 19.5157 28.9895 19.3832 28.6697C19.2508 28.3499 19.2161 27.9981 19.2836 27.6586C19.3512 27.3191 19.5178 27.0073 19.7626 26.7626C20.0073 26.5178 20.3191 26.3511 20.6586 26.2836C20.9981 26.2161 21.3499 26.2508 21.6697 26.3832C21.9895 26.5157 22.2628 26.74 22.4551 27.0277C22.6474 27.3155 22.75 27.6539 22.75 28C22.75 28.4641 22.5656 28.9092 22.2374 29.2374C21.9093 29.5656 21.4641 29.75 21 29.75ZM22.75 22.75C22.75 23.2141 22.5656 23.6592 22.2374 23.9874C21.9093 24.3156 21.4641 24.5 21 24.5C20.5359 24.5 20.0908 24.3156 19.7626 23.9874C19.4344 23.6592 19.25 23.2141 19.25 22.75V14C19.25 13.5359 19.4344 13.0907 19.7626 12.7626C20.0908 12.4344 20.5359 12.25 21 12.25C21.4641 12.25 21.9093 12.4344 22.2374 12.7626C22.5656 13.0907 22.75 13.5359 22.75 14V22.75Z"
                                    fill="#9CA3AF"
                                />
                            </svg>
                            <div className="h-12 flex-col justify-start items-start gap-3.5 flex">
                                <div className="self-stretch justify-start items-start inline-flex">
                                    <div className="grow shrink basis-0 text-center text-gray-500 text-base font-normal font-['Inter'] leading-normal">
                                        Вы уверены, что хотите удалить ответы
                                        этого пользователя?
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="self-stretch px-6 pb-6 justify-center items-center gap-4 inline-flex">
                            <div
                                role="button"
                                onClick={confirmDeleteUserAnswers}
                                className="px-3 py-2 bg-[#c81e1e] rounded-lg justify-center items-center gap-2 flex"
                            >
                                <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
                                    Да, я уверен
                                </div>
                            </div>
                            <div
                                role="button"
                                onClick={() => setIsModalOpen(false)}
                                className="px-3 py-2 rounded-lg border border-gray-200 justify-center items-center gap-2 flex"
                            >
                                <div className="text-[#1f2a37] text-xs font-medium font-['Inter'] leading-[18px]">
                                    Нет, отменить
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UserMode;
