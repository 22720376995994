const ExcelIconSVG = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.7812 4.39043V14.9529C13.7812 15.5217 13.3219 15.9811 12.7531 15.9811H3.24688C2.67813 15.9811 2.21875 15.5217 2.21875 14.9529V1.04668C2.21875 0.47793 2.67813 0.0185547 3.24688 0.0185547H9.40938L13.7812 4.39043Z"
                fill="#23A566"
            />
            <path
                d="M10.5282 7.96582H5.47192C5.29067 7.96582 5.1438 8.1127 5.1438 8.29395V12.6971C5.1438 12.8783 5.29067 13.0252 5.47192 13.0252H10.5313C10.7125 13.0252 10.8594 12.8783 10.8594 12.6971V8.29707C10.8563 8.11582 10.7094 7.96582 10.5282 7.96582ZM5.80005 10.0783H7.67192V10.9127H5.80005V10.0783ZM8.32817 10.0783H10.2V10.9127H8.32817V10.0783ZM10.2 9.42207H8.32817V8.6252H10.2V9.42207ZM7.67192 8.6252V9.42207H5.80005V8.6252H7.67192ZM5.80005 11.5721H7.67192V12.3689H5.80005V11.5721ZM8.32817 12.3689V11.5721H10.2V12.3689H8.32817Z"
                fill="white"
            />
            <path
                d="M9.99683 4.23125L13.7812 7.29688V4.41875L11.6343 3.17188L9.99683 4.23125Z"
                fill="black"
                fillOpacity="0.0980392"
            />
            <path
                d="M13.7813 4.39043H10.4375C9.8688 4.39043 9.40942 3.93105 9.40942 3.3623V0.0185547L13.7813 4.39043Z"
                fill="#8ED1B1"
            />
        </svg>
    );
};

export default ExcelIconSVG;
